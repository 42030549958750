import React from 'react'
import SEO from '../../components/seo'
import SubPageLayoutNoHero from '../../layouts/subPageLayoutNoHero'
import styled from 'styled-components'
import { Layout } from '../../components/styled/layout'
import { Section } from '../../components/styled/section'
import SubPageImgHero from '../../components/subPageImgHero'
import ChooseRegion from '../../components/small_web_comp/ChooseRegion'
const PageSection = styled(Section)`
  display: flex;
  align-items: center;
  margin: 15px auto;
`
const Text = styled.div`
  flex: 1;
  margin-top: -3rem;
  h4 {
    border-bottom: 2px solid #dddddd;
  }
`
const DS = () => {
  const title = 'Diagnose og systemscan'
  const description1 = ''
  const Img = require('../../images/Diagnose.jpg')
  return (
    <Layout>
      <SubPageLayoutNoHero>
        <SEO title={title} />
        <SubPageImgHero title={title} description1={description1} Img={Img}></SubPageImgHero>
        <PageSection>
          <Text>
            <h4>Diagnose og systemscan</h4>
            <p>
              Får du opp feilmeldinger på dashbordet i bilen din?<br></br>
              BilXtra-verkstedene har flere diagnoseapparater for å kunne avdekke feil på
              styreenheter, sensorer og følere på de fleste biler. Vi finner feilen, skifter eller
              reparerer komponenter, og sletter så de feilkodene som har vært.
            </p>
            <ChooseRegion />
          </Text>
        </PageSection>
      </SubPageLayoutNoHero>
    </Layout>
  )
}

export default DS
